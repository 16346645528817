import React from 'react';

const Help = () => {
    return (
        <div>
            <div>

                <div className="flex h-[900px] w-full items-center justify-center flex-col">
                    <div>
                        <div className="text-3xl font-bold text-gray-500">
                            Coming soon...
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default Help;